<template>
	<div class="rootDiv">
		<div class="retrieve">
			<div class="searchDiv flex flex-jb">
				<div class="flex flex-ac">
					<div class="mr-30 flex flex-ac">
						<span class="fs-16 flex1 mr-20">价格分区：</span>
						<el-select v-model="retrieveForm.goodSeriesType" clearable placeholder="请选择价格分区">
							<el-option v-for="item in goodSeriesTypes" :key="item.value" :label="item.key"
								:value="item.value"></el-option>
						</el-select>
					</div>
					<div class="mr-30 flex flex-ac">
						<span class="fs-16 flex1 mr-20">实际等级：</span>
						<el-select v-model="retrieveForm.gradeId" clearable placeholder="请选择价格分区">
							<el-option v-for="item in gradeConsumes" :key="item.id" :label="item.grade" :value="item.id"></el-option>
						</el-select>
					</div>
					<div>
						<el-button type="primary" @click="submitSearch">查询</el-button>
					</div>
				</div>
				<div class="flex flex-ac">
					<div class="fs-16 fc-6b9eff pointer" @click="addList">
						<i class="el-icon-circle-plus-outline mr-10 fs-18"></i>新增
					</div>
				</div>
			</div>
		</div>
		<TableHeightFull class="tableDiv pall-30">
			<!-- 表格 -->
			<EleTable ref="tableRef" slot="table" :tableData="orderList" :columns="columns" height="100%" :border="false"
				v-loading="tabLoading" :row-style="{ cursor: 'pointer' }">
			</EleTable>
			<PagingComponent slot="after" :page-info="pageInfo" @getTableData="saleOrder" />
		</TableHeightFull>
		<el-dialog :title="dialogTitle" :visible.sync="dialogVisible" close="cancel" width="35%" append-to-body
			@close="resetForm">
			<el-form :model="formData" ref="ruleForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="价格分区" label-width="130px" prop="goodSeriesType" v-if="'goodSeriesType' in formData"
					:rules="{required: true, message: '价格分区不能为空', trigger: 'blur'}">
					<el-select v-model="formData.goodSeriesType" placeholder="请选择价格分区">
						<el-option v-for="item in goodSeriesTypes" :key="item.value" :label="item.key"
							:value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="实际等级" label-width="130px" prop="gradeId" v-if="'gradeId' in formData"
					:rules="{required: true, message: '实际等级不能为空', trigger: 'blur'}">
					<el-select v-model="formData.gradeId" placeholder="请选择等级" :disabled="dialogTitle==='编辑等级'?true:false">
						<el-option v-for="item in gradeConsumes" :key="item.id" :label="item.grade" :value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="概率" label-width="130px" prop="chance" v-if="'chance' in formData"
					:rules="{required: true, message: '概率不能为空', trigger: 'blur'}">
					<el-input type="text" v-model="formData.chance"></el-input>
				</el-form-item>
				<el-form-item label="保底抽赏数量" label-width="130px" prop="buyNum" v-if="'buyNum' in formData"
					:rules="{required: true, message: '数量不能为空', trigger: 'blur'}">
					<el-input type="text" v-model="formData.buyNum"></el-input>
				</el-form-item>
				<el-form-item label="累计消费起点" label-width="130px" prop="startConsume" v-if="'startConsume' in formData"
					:rules="{required: true, message: '不能为空', trigger: 'blur'}">
					<el-input type="text" v-model="formData.startConsume"></el-input>
				</el-form-item>
				<el-form-item label="累计消费结束" label-width="130px" prop="endConsume" v-if="'endConsume' in formData"
					:rules="{required: true, message: '不能为空', trigger: 'blur'}">
					<el-input type="text" v-model="formData.endConsume"></el-input>
				</el-form-item>
				<el-form-item label="清空用户消费" label-width="130px" prop="isClearConsume" :rules="{required: true, message: '开关必填', trigger: 'blur'}">
					<el-switch v-model="formData.isClearConsume" :active-value="true" :inactive-value="false"></el-switch>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="cancel">取 消</el-button>
				<el-button type="primary" @click="editOkSubmit">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
	import indexMixin from "@/utils/indexMixin"
	import {
		mapState
	} from 'vuex'
	export default {
		name: 'soldOrder',
		mixins: [indexMixin],
		components: {
			"EleTable": (resolve) => require(["@/components/public/TSimpleTable"], resolve),
			"TableHeightFull": (resolve) => require(["@/components/public/TableHeightFull"], resolve),
			"PagingComponent": (resolve) => require(["@/components/public/PagingComponent"], resolve),
		},
		computed: {
			...mapState('menu', {
				'goodSeriesTypes': state => state.dictList ? state.dictList.good_series_type : [], //一番赏分区类型
			})
		},
		data() {
			return {
				retrieveForm: {
					gradeId: '',
					goodSeriesType: ''
				},
				orderList: [],
				dialogVisible: false,
				dialogTitle: '',
				gradeConsumes: [], //实际等级
				formData: {
					buyNum: '',
					goodSeriesType: '',
					chance: '',
					gradeId: '',
					id: '',
					startConsume:'',
					endConsume:'',
					isClearConsume:true
				},
				columns: [{
					label: '价格分区',
					prop: 'goodSeriesType',
					align: 'left',
					formatData: (e) => {
						let ele = this.goodSeriesTypes.find(ele => ele.value == e)
						return ele ? ele.key : e
					}
				}, {
					label: '实际等级',
					prop: 'gradeId',
					align: 'left',
					formatData: (e) => {
						let ele = this.gradeConsumes.find(ele => ele.id == e)
						return ele ? ele.grade : ''
					}
				}, {
					label: '概率',
					prop: 'chance',
					align: 'left',
				}, {
					label: '保底抽赏数量',
					prop: 'buyNum',
					align: 'left',
				},   {
					label: '累计消费起点',
					prop: 'startConsume',
					align: 'left',
				},  {
					label: '累计消费结束',
					prop: 'endConsume',
					align: 'left',
				},  {
					label: '是否清空消费',
					prop: 'isClearConsume',
					align: 'left',
					formatData: (e) => {
						return e ?'开启':'关闭'
					}
				}, {
					columnType: 'button',
					label: '操作',
					align: 'right',
					fixed: 'right',
					width: '420',
					buttons: [{
						type: 'text',
						text: '编辑',
						fn: this.handleEdit
					},{
						type: 'text',
						text: '删除',
						fn: this.handeCancel
					}]
				}],
				isDataLoaded: false,
				tabLoading: false,
			}
		},
		mounted() {
			this.pageInfo.pageNum = 1
			this.saleOrder()
			this.findGrade()
		},
		methods: {
			//查询
			submitSearch() {
				this.pageInfo.pageNum = 1
				this.saleOrder()
			},
			saleOrder() {
				this.tabLoading = true
				this.$http.get('/seriesAreaGrade/findSeriesAreaGradeList', {
					params: {
						currentPage: this.pageInfo.pageNum,
						pageSize: this.pageInfo.pageSize,
						goodSeriesType: this.retrieveForm.goodSeriesType,
						gradeId: this.retrieveForm.gradeId,
					}
				}).then(res => {
					this.tabLoading = false
					this.orderList = res.data.data.list
					this.pageInfo.total = res.data.data.total
					this.isDataLoaded = true
				})
			},
			addList() {
				this.dialogTitle = "新增等级"
				this.dialogVisible = true
			},
			handeCancel(row){
				this.$confirm('您确认删除？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(_ => {
					this.$http.post('/seriesAreaGrade/removeSeriesAreaGradeById', {
						id: row.id
					}).then(res => {
						this.saleOrder()
						this.$message({
							message: '删除成功',
							type: 'success'
						})
					})
				}).catch(_ => {})
			},
			//导出
			handleEdit(row) {
				this.dialogTitle = "编辑等级"
				this.dialogVisible = true
				this.formData = JSON.parse(JSON.stringify(row))
				// this.$http.get('/seriesAreaGrade/findSeriesAreaGradeById', {
				// 	params: {
				// 		id: row.id
				// 	}
				// }).then(res => {
				// 	let formData = res.data.data
				// 	this.formData = formData
				// 	this.tabLoading = false
				// 	this.isDataLoaded = true
				// })
			},
			editOkSubmit() {
				if (this.formData.buyNum == 0) {
					return this.$message({
						message: '保底数量不能为0',
						type: 'warning',
					})
				}
				this.$refs['ruleForm'].validate((valid) => {
					if (valid) {
						this.butLoading = true
						let apiUrl = ""
						if (this.dialogTitle == '新增等级') {
							apiUrl = "/seriesAreaGrade/addSeriesAreaGrade"
						} else if (this.dialogTitle == '编辑等级') {
							apiUrl = "/seriesAreaGrade/editSeriesAreaGrade"
						}
						this.$http.post(apiUrl, this.formData).then(({
							data: result
						}) => {
							this.$message({
								message: this.dialogTitle + '成功',
								type: 'success'
							})
							this.butLoading = false
							this.saleOrder()
							this.cancel()
						}).catch((err) => {
							this.butLoading = false
						})
					}
				})
				// let goodData = JSON.parse(JSON.stringify(this.formData))
				// this.$http.post('/seriesAreaGrade/editSeriesAreaGrade', goodData).then(res => {
				// 	this.cancel()
				// })
			},
			cancel() {
				this.dialogVisible = false
				this.resetForm()
			},
			//一番赏商品真实等级
			findGrade() {
				this.$http.get('/grade/findGrade').then(({
					data: result
				}) => {
					this.gradeConsumes = result.data
				})
			},
			//重置清除校验
			resetForm() {
				Object.assign(
					this.$data.formData,
					this.$options.data().formData
				)
				this.$nextTick(() => {
					this.$refs.ruleForm.clearValidate()
				})
			},
		}
	}
</script>
<style>
</style>